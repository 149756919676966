<template>
  <div>
    <b-card>
 
      <div class="container app-view">
        <div class="app_title_box">
        <h4>Privacy Policy Content Page</h4>

      </div>

        <quill-editor
          v-model="content"
          :options="snowOption"
          @change="handleEditorChange"
        />
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mt-2"
          @click="createPageContent"
        >
          Save Changes
        </b-button>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    quillEditor,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      content: '<p>Enter about here</p>',
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    contents() {
      return this.$store.state.page_content.privacy_contents
    },
  },
  watch: {
    '$store.state.page_content.privacy_contents': {
      handler(oldValue, newValue) {
        if (newValue.content === undefined || newValue.content === null) {
          this.content = this.contents.content
        } else {
          this.content = this.contents.content
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getContent()
  },
  methods: {
    showModal() {
      this.$bvModal.show('app-modal')
    },
    getContent() {
      return this.$store.dispatch('page_content/getAllPrivacyContent', 'privacy-policy')
    },
    createPageContent() {
      if (this.contents.content === undefined || this.contents.content === null) {
        const data = { name: 'privacy-policy', content: this.content }
        this.$store.dispatch('page_content/createPrivacyContent', data)
      } else {
        const data = { id: this.contents.id, name: 'privacy-policy', content: this.content }

        this.$store.dispatch('page_content/updatePrivacyContent', data)
      }
    },
    handleEditorChange(content) {
      if (content.html === '<h1><br></h1>') {
        this.content = ''
      } else {
        this.content = content.html
      }
    },
  },
}
</script>

<style>

</style>
